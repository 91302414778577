<template>
  <b-card class="mb-4 border-0">
    <template #header>
      <strong>
        {{ title }}
      </strong>
      <div class="info-button-wrapper float-right">
        <div id="info-default-configs" v-b-hover="handleInfoHover">
          <b-icon v-if="isHovered" icon="info-circle-fill" variant="info" scale="2"></b-icon>
          <b-icon v-else icon="info-circle" scale="2"></b-icon>
        </div>
        <b-tooltip
          id="info-default-configs-tooltip"
          target="info-default-configs"
          triggers="hover"
          placement="left"
          custom-class="dark-tooltip">
          Here you can configure Ship Via exceptions for specific clients. In case you
          want to have a different shipment to members of a specific client you can do it here.
        </b-tooltip>
      </div>
    </template>
    <div class="exception-holder">
      <b-row class="mb-4">
        <div class="exception-col" id="exception_method_multiselect">
          <b-form-group
            id="input-group-1"
            label="Ship via methods *"
            class=""
            description="Select one or more ship via methods"
            invalid-feedback="At least one Ship via method is required"
            required
            :state="isValid('exception.shipViaMethods')">
            <multiselect
                  v-model="exception.shipViaMethods"
                  tag-placeholder="Add a new ship via method"
                  placeholder="Ship via methods"
                  :options="shipViaMethodOptions"
                  track-by="value"
                  label="text"
                  class="exception-method-multiselect"
                  :multiple="true"
                  :taggable="false"></multiselect>
          </b-form-group>
        </div>
      </b-row>
      <b-row class="mb-4">
        <div class="exception-col">
          <b-form-group
            id="input-group-1"
            label="Ship via *"
            class="exception-label"
            description="Insert ship via for orders"
            invalid-feedback="Ship via is required"
            required
            :state="isValid('exception.shipVia')">
              <b-form-input
                type="text"
                maxlength=254
                v-model="exception.shipVia"
                placeholder="ship via name"
                class="exception-textbox">
              </b-form-input>
          </b-form-group>
        </div>
      </b-row>
      <b-row class="mb-4">
        <div class="exception-col">
           <b-form-group
            id="input-group-1"
            label="Clients *"
            class="exception-label clients-exceptions-group"
            description="Insert here the clients you want to configure this exception for. Start writing and click enter to add it."
            invalid-feedback="Clients are required"
            required
            :state="isValid('exception.clients')">
                <client-multi-select :config="multiClientsConfig"
                                      :selected-value="exception.clients"
                                      @selection-change="onClientFilterSelectionChange"></client-multi-select>
          </b-form-group>
        </div>
      </b-row>
      <b-row class="mb-4">
        <div>
          <div class="exception-col">
            <b-form-group
            id="fg-units"
            class="exception-label clients-exceptions-group"
            label="Units *"
            description="Insert here the units you want to configure this exception for. Start writing and click enter to add it."
            invalid-feedback="At least one unit is required"
            required
            :state="isValid('exception.units')">
            <multiselect
              v-model="exception.units"
              tag-placeholder="Add a new unit to the exception"
              placeholder="Units"
              :options="unitOptions"
              track-by="code"
              label="name"
              class="exception-multiselect"
              :multiple="true"
              :taggable="false"></multiselect>
          </b-form-group>
          </div>
        </div>
      </b-row>
      <b-row class="mb-4">
        <div class="exception-col">
           <b-form-group
            id="input-group-1"
            label="Short Description"
            class="exception-label clients-exceptions-description-group"
            description="You can add a description to your exception. Not required"
            invalid-feedback="Clients are required">
              <b-form-input
                type="text"
                maxlength=254
                v-model="exception.description"
                placeholder="Short Description"
                class="exception-textbox"></b-form-input>
          </b-form-group>
        </div>
      </b-row>
      <b-row class="mt-3" style="margin-top:0 !important">
        <b-col class="d-flex align-items-center justify-content-end mr-4 btn-save-exception">
          <b-button class="ml-3"
                    @click="submitForm"
                    type="button"
                    :disabled="saving"
                    variant="primary">
            <b-spinner label="Loading..." variant="light" small v-if="saving"></b-spinner>
            <span v-else>Save</span>
          </b-button>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>

import ClientMultiSelect from '@/components/Clients/ClientMultiSelect.vue';
import Multiselect from 'vue-multiselect';
import axios from 'axios';
import Vue from 'vue';
import { required } from 'vuelidate/lib/validators';
import translations from '@/translations';
import { UNIT_DPT, UNIT_BLOOM, UNIT_MOVE } from '@/scripts/constants';

const AUTO_SHIP_VIA_METHOD = 'auto';


export default {
  name: 'client-exception-configurations',
  async created() {
    await this.loadShipViaMethods();
    if (this.isEditing) {
      const shipViaID = this.exceptionID;
      await this.$store.dispatch('OrderManagement/loadShipViaExceptions', { shipViaID });
      await this.populateEditForm();
    }
  },
  props: {
    exceptionID: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      exception: {
        shipViaMethods: [],
        shipVia: '',
        clients: [],
        description: '',
        units: [],
      },
      isHovered: false,
      shipViaMethodOptions: [],
      saving: false,
      editingException: null,
      batchSizeLimit: 50,
      unitOptions: [
        { code: UNIT_DPT, name: translations.units[UNIT_DPT] },
        { code: UNIT_BLOOM, name: translations.units[UNIT_BLOOM] },
        { code: UNIT_MOVE, name: translations.units[UNIT_MOVE] },
      ],
      multiClientsConfig: {
        multiple: true,
        limit: 3,
      },
    };
  },
  computed: {
    rules() {
      return {
        exception: {
          shipViaMethods: { required },
          shipVia: { required },
          clients: { required },
          description: '',
          units: { required },
        },
      };
    },
    title() {
      return this.exceptionID ? 'Edit client exception' : 'Add client exception';
    },
    isEditing() {
      return this.exceptionID !== null;
    },
  },
  validations() {
    return this.rules;
  },
  components: {
    Multiselect,
    ClientMultiSelect,
  },
  methods: {
    handleInfoHover(hovered) {
      this.isHovered = hovered;
    },
    getValidator(field) {
      const parts = field.split('.');
      let rootValidator = this.$v;

      parts.forEach(part => {
        rootValidator = rootValidator[part] || {};
      });
      return rootValidator;
    },
    isValid(field) {
      const validator = this.getValidator(field);
      const invalid = validator.$invalid;
      const value = validator.$model;

      if (!value && !('required' in validator)) {
        return true;
      }

      return !invalid;
    },
    onClientFilterSelectionChange(clients) {
      this.exception.clients = clients;
    },
    async loadShipViaMethods() {
      await axios
        .get('v1/shipvias/methods', { params: { allowed_in_exceptions: true } })
        .then(response => {
          // Populate ship via method options
          response.data.ship_via_methods.forEach(sv => {
            const methodText = translations.ship_vias.methods[sv.name] || sv.name;
            // Pre select auto
            if (sv.name === AUTO_SHIP_VIA_METHOD && !this.isEditing) {
              this.exception.shipViaMethods.push({ value: sv.name, text: methodText });
            }
            this.shipViaMethodOptions.push({ value: sv.name, text: methodText });
          });
        })
        .catch(response => {
          this.$noty.error('Unable to load ship via methods');
          console.error(response);
        });
    },
    async fetchClientBatches(allIds) {
      if (!allIds || allIds.length === 0) return;
      const batches = [];

      for (let i = 0; i < allIds.length; i += this.batchSizeLimit) {
        batches.push(allIds.slice(i, i + this.batchSizeLimit));
      }

      const fetchPromises = batches.map(batch => this.fetchClientsByIds(batch));

      await Promise.all(fetchPromises);
    },
    async fetchClientsByIds(clientIDs) {
      if (!clientIDs || clientIDs.length === 0) return;

      const urlSearchParams = new URLSearchParams();
      clientIDs.forEach(clientID => urlSearchParams.append('id', clientID));
      urlSearchParams.append('limit', clientIDs.length);
      urlSearchParams.append('offset', 0);

      this.loading = true;
      await axios.get(`v2/clients?${urlSearchParams.toString()}`)
        .then(response => {
          response.data.items.forEach(client => {
            this.exception.clients.push({
              value: client.id,
              text: `${client.display_name} (id:${client.id})`,
            });
          });
        }).catch(response => {
          this.$noty.error('Unable to load clients');
          console.error(response);
        }).finally(() => { this.loading = false; });
    },
    async reloadFileConfigs() {
      this.exception = [];
    },
    async saveException() {
      if (this.$v.$invalid) {
        this.$noty.error('Please Check all fields.');
        return;
      }

      const clientIds = [ ...new Set(this.exception.clients.map(item => item.value)) ];
      const shipViaMethods = [ ...new Set(this.exception.shipViaMethods.map(item => item.value)) ];
      const params = {
        ship_via_methods: shipViaMethods,
        ship_via: this.exception.shipVia,
        client_ids: clientIds,
        description: this.exception.description,
        units: this.parseUnits(this.exception.units),
      };

      this.saving = true;
      try {
        await axios.post('v1/shipvia/exception', params);
        await this.reloadFileConfigs();
        this.$noty.success('Exception saved');
        setTimeout(() => {
          this.$router.go(-1);
        }, 1000);
      } catch (error) {
        this.$noty.error('Unable to save client exception');
        console.error('error saving: ', error);
      } finally {
        this.saving = false;
      }
    },
    async updateException() {
      if (this.$v.$invalid) {
        this.$noty.error('Please, fill the required fields.');
        return;
      }

      const clientIds = [ ...new Set(this.exception.clients.map(item => item.value)) ];
      const shipViaMethods = [ ...new Set(this.exception.shipViaMethods.map(item => item.value)) ];
      const payload = {
        ship_via_id: this.exceptionID,
        ship_via: this.exception.shipVia,
        description: this.exception.description,
        ship_via_methods: shipViaMethods,
        client_ids: clientIds,
        units: this.parseUnits(this.exception.units),
      };
      this.saving = true;

      this.$store.dispatch('OrderManagement/updateShipViaException', payload)
        .then(() => {
          Vue.prototype.$noty.success('Ship via exception saved successfully!');
          this.$router.go(-1);
        })
        .catch(err => {
          const errMsg = `Unable to save ship via exception: ${err.response ? err.response.data.message : err}`;
          Vue.prototype.$noty.error(errMsg);
          console.error(errMsg);
        })
        .finally(() => {
          this.saving = false;
        });
    },
    async populateEditForm() {
      if (this.editingException == null) {
        const exceptions = this.$store.getters['OrderManagement/getShipViaExceptions'];

        // loop exceptions and find the one with the id
        exceptions.forEach(exception => {
          if (exception.exception_id === this.exceptionID) {
            this.editingException = exception;
          }
        });
      }
      this.exception.shipVia = this.editingException.ship_via;
      this.exception.description = this.editingException.description;

      // Populate ship via methods
      this.editingException.ship_via_methods.forEach(method => {
        const methodText = translations.ship_vias.methods[method] || method;
        this.exception.shipViaMethods.push({ value: method, text: methodText });
      });

      // Populate client options
      await this.fetchClientBatches(this.editingException.client_ids);

      // Populate units
      this.exception.units = this.findMatchingUnits(this.editingException.units);
    },
    async submitForm() {
      if (this.isEditing) {
        await this.updateException();
      } else {
        await this.saveException();
      }
    },
    parseUnits(units) {
      if (!units || units.length === 0) {
        return null;
      }

      return this.exception.units.map(u => u.code);
    },
    findMatchingUnits(units) {
      const matches = [];

      if (!units || units.length === 0) {
        return matches;
      }

      units.forEach(unit => {
        const index = this.unitOptions.findIndex(u => u.code === unit);

        if (index > -1) {
          matches.push(this.unitOptions[index]);
        }
      });

      return matches;
    },
  },
};
</script>
